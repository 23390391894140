import * as React from "react";
import Bgshape from "../svgs/bgshape.inline.svg";

interface NthShapeProps {
  order: number | string;
}

const NthShape: React.FC<NthShapeProps> = ({ order }) => {
  return (
    <div
      className="
        relative
        w-[80px] h-[140px]
        lg:w-[100px] lg:h-[150px]
      "
    >
      <Bgshape className="absolute inset-0 w-full h-full" />
      <span className="absolute inset-0 z-10 flex justify-center items-center pr-[10%]">
        <span className="block text-lp-black">
          <span className="inline-block align-middle text-[14px] lg:text-[20px]">
            第
          </span>
          <span className="inline-block align-middle text-[26px] lg:text-[32px] py-[4px] relative -top-px">
            {order}
          </span>
          <span className="inline-block align-middle text-[14px] lg:text-[20px]">
            部
          </span>
        </span>
      </span>
    </div>
  );
};

export { NthShape };
