import * as React from "react";
import ctl from "@netlify/classnames-template-literals";
import { FadeIn } from "./fade-in";

interface IItemProps {
  title: string;
}
interface ITextListProps {
  className?: string;
}
interface ITextList extends React.FC<ITextListProps> {
  Item: React.FC<IItemProps>;
}

const Item: React.FC<IItemProps> = ({ children, title }) => {
  const dtClass = ctl(`
    font-bold
    md:font-normal
    md:table-cell
    md:whitespace-nowrap
    md:align-top
    md:pt-vgap-sm
  `);
  const ddClass = ctl(`
    md:table-cell
    md:w-full
    md:align-top
    md:pl-15px
    md:pt-vgap-sm
  `);
  return (
    <div className="md:table-row">
      <dt className={dtClass}>{title}</dt>
      <dd className={ddClass}>{children}</dd>
    </div>
  );
};

const TextList: ITextList = ({ className, children }) => (
  <div className={className}>
    <div className="pb-vgap-lg">
      <FadeIn>
        <dl className="md:table space-y-vgap-md md:space-y-0 md:-mt-vgap-sm lg:text-lg">
          {children}
        </dl>
      </FadeIn>
    </div>
  </div>
);

TextList.Item = Item;

export { TextList };
