import * as React from 'react';
import { SubmitFlyout } from "../components/submit-flyout";
import { BackToTop } from "../components/back-to-top";
import { Footer } from "../components/footer";

interface LayoutProps {
  registrationUrl?: string;
}

const Layout: React.FC<LayoutProps> = ({ children, registrationUrl }) => {
  return (
    <div>
      <main>{children}</main>
      <Footer />
      <SubmitFlyout href={registrationUrl} />
      <BackToTop />
    </div>
  );
};

export { Layout };
