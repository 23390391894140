import * as React from "react";
import ctl from "@netlify/classnames-template-literals";
import Img, { FluidObject } from "gatsby-image";
import { Richtext } from "./richtext";

type ImgPosition = "right" | "left";
type UlStyle = "check" | "bigText";

interface TextWrapperProps {
  html?: string;
  jsx?: React.ReactElement;
  ulStyle?: UlStyle;
}
interface ImgProps {
  fluid?: FluidObject;
  rawSrc?: string;
}

interface ImgTextBlockProps {
  imgFluidWide?: FluidObject;
  imgFluidNarrow?: FluidObject;
  imgWide?: string;
  imgNarrow?: string;
  imgPosition: ImgPosition;
  ulStyle?: UlStyle;
  html?: string;
  jsx?: React.ReactElement;
  className?: string;
}

const TextWrapperLeft: React.FC<TextWrapperProps> = ({
  html,
  jsx,
  ulStyle,
}) => {
  const className = ctl(`
    mx-20px ${null /* for narrow view */}
    lg:relative lg:-mr-50px lg:ml-30px ${null /* to put text on img */}
    xl:-mr-30px xl:ml-60px ${null /* for large view */}
    lg:pt-vgap-md xl:pt-vgap-lg ${null /* need upper spacing for large view */}d
  `);
  return (
    <div className={className}>
      {html && (
        <Richtext html={html} h2Style="leftBorder" ulStyle={ulStyle}></Richtext>
      )}
      {jsx && jsx}
    </div>
  );
};
const TextWrapperRight: React.FC<TextWrapperProps> = ({ html, jsx }) => {
  const className = ctl(`
    mx-20px
    lg:relative lg:mr-30px lg:ml-30px xl:mr-40px
    lg:pt-vgap-md xl:pt-vgap-lg
  `);
  return (
    <div className={className}>
      {html && <Richtext html={html} h2Style="rightBorder"></Richtext>}
      {jsx && jsx}
    </div>
  );
};

const ImgWide: React.FC<ImgProps> = ({ fluid, rawSrc }) => {
  const className = "hidden lg:block";
  if (fluid) {
    return <Img className={className} fluid={fluid} alt="" />;
  } else {
    return <img className={className} src={rawSrc} alt="" />;
  }
};

const ImgNarrow: React.FC<ImgProps> = ({ fluid, rawSrc }) => {
  const className = "block w-full";
  if (fluid) {
    return <Img className={className} fluid={fluid} alt="" />;
  } else {
    return <img className={className} src={rawSrc} alt="" />;
  }
};

const ImgTextBlock: React.FC<ImgTextBlockProps> = ({
  imgFluidWide,
  imgFluidNarrow,
  imgWide,
  imgNarrow,
  imgPosition = null,
  ulStyle = null,
  className,
  html = null,
  jsx = null,
}) => {
  const containerCls = ctl(`
    -mx-20px lg:-mx-30px xl:-mx-60px
    lg:flex lg:items-center
    ${imgPosition === "right" && "flex-row"}
    ${imgPosition === "left" && "flex-row-reverse"}
    pb-vgap-xl
    ${className}
  `);
  return (
    <div className={containerCls}>
      <div className="w-full lg:w-1/2">
        {imgPosition === "right" && (
          <TextWrapperLeft ulStyle={ulStyle} html={html} jsx={jsx} />
        )}
        {imgPosition === "left" && <TextWrapperRight html={html} jsx={jsx} />}
      </div>
      <div className="w-full lg:w-1/2">
        {imgFluidWide && <ImgWide fluid={imgFluidWide} rawSrc={imgWide} />}
        <div className="block lg:hidden">
          {imgFluidNarrow && (
            <ImgNarrow fluid={imgFluidNarrow} rawSrc={imgNarrow} />
          )}
        </div>
      </div>
    </div>
  );
};

export { ImgTextBlock };
