import * as React from "react";
import Img, { FluidObject } from "gatsby-image";
import tw from "twin.macro";
import { css } from "@emotion/css";
import { FadeIn } from "./fade-in";

const styles = {
  noteList: css`
    ${tw`text-xs md:text-sm lg:text-xs xl:text-sm`}
    ${tw`pt-vgap-sm space-y-vgap-xs`}
    li {
      margin-left: 1.5em;
      ${tw`relative`}
    }
    li::before {
      content: "※";
      ${tw`block absolute`}
      left: -1.5em;
      top: 0;
    }
  `,
};

const Iframe: React.FC = () => (
  <iframe
    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12967.336924827638!2d139.6994178!3d35.6564556!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x8e2fa0cdb7d1589d!2z44OI44OD44OX44Oe44ON44K444Oh44Oz44OI5qCq5byP5Lya56S-!5e0!3m2!1sja!2sjp!4v1459162947126"
    width="500"
    height="180"
    title="会社の地図"
    className="absolute inset-0 w-full h-full"
    allowFullScreen
  ></iframe>
);

interface ProfileImgProps {
  imgFluid?: FluidObject;
}

const ProfileImg: React.FC<ProfileImgProps> = ({ imgFluid }) => (
  <>
    {imgFluid && <Img className="block w-full" fluid={imgFluid} alt="" />}
    {!imgFluid && <div>IMG</div>}
  </>
);

interface AddressBlockProps {
  className?: string;
  imgFluid?: FluidObject;
}

const AddressBlock: React.FC<AddressBlockProps> = ({ className, imgFluid }) => {
  return (
    <div className={className}>
      <div className="lg:-ml-30px">
        <div className="lg:flex w-full">
          <div className="flex-none lg:pl-30px lg:w-3/5 xl:w-1/2">
            <div className="flex">
              <div className="flex-none w-1/4 md:max-w-[140px] lg:w-[108px] xl:w-1/4">
                <ProfileImg imgFluid={imgFluid} />
              </div>
              <div className="flex-grow pl-15px flex flex-col justify-center items-left">
                <div className="text-xs md:text-base lg:text-sm">
                  <FadeIn>
                    <span className="whitespace-nowrap">ドラッカー専門の</span>
                    <wbr />
                    <span className="whitespace-nowrap">
                      経営チームコンサルティング会社
                    </span>
                  </FadeIn>
                </div>
                <div className="text-base md:text-lg lg:text-xl xl:text-2xl md:pt-vgap-sm">
                  <FadeIn>
                    <span className="whitespace-nowrap">
                      トップマネジメント
                    </span>
                    <wbr />
                    <span className="whitespace-nowrap">株式会社</span>
                  </FadeIn>
                </div>
                <div className="text-xs md:text-base lg:text-sm md:pt-vgap-sm">
                  <FadeIn>渋谷区桜丘町26-1セルリアンタワー15階</FadeIn>
                </div>
                <FadeIn>
                  <ul className={styles.noteList}>
                    <li>
                      弊社と同じ社名で電話営業している企業様がありますが弊社と関係ありません。
                    </li>
                  </ul>
                </FadeIn>
              </div>
            </div>
          </div>
          <div className="flex-grow lg:pl-30px xl:w-1/2 pt-vgap-lg lg:pt-0">
            <div className="relative pt-[60%] lg:pt-[150px] xl:pt-[34%]">
              <Iframe />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { AddressBlock };
