import * as React from "react";
import * as cheerio from "cheerio";
import { css } from "@emotion/css";
import tw from "twin.macro";
import { FadeIn } from "./fade-in";

const s = {
  richtext: css`
    ul {
      ${tw`pt-vgap-sm md:-ml-4 md:-mt-1`}
    }
    ul li {
      ${tw`inline text-sm md:text-base lg:text-lg`}
      ${tw`md:ml-4 md:inline-block md:whitespace-nowrap`}
    }
    ul li::before {
      content: "・";
      ${tw`inline-block align-middle`}
    }
  `,
};

interface ITextSetProps {
  width?: string;
  className?: string;
  html: string;
}
const TextSet: React.FC<ITextSetProps> = ({
  width = "8rem",
  className,
  html,
}) => {
  const $ = cheerio.load(html);
  const title = $("h4").html();
  $("h4").remove();
  return (
    <div className={className}>
      <FadeIn>
        <div className="md:table w-full mb-vgap-lg lg:text-lg">
          <dl className="md:table-row">
            <dt
              className="md:table-cell align-top whitespace-nowrap"
              style={{ width: width }}
            >
              <span className="block border border-lp-foreground text-center py-2 px-3">
                {title}
              </span>
            </dt>
            <dd className="md:table-cell align-top pt-vgap-md md:pt-1 md:pl-15px lg:pl-30px">
              <div
                className={s.richtext}
                dangerouslySetInnerHTML={{ __html: $.root().html() }}
              ></div>
            </dd>
          </dl>
        </div>
      </FadeIn>
    </div>
  );
};

export { TextSet };
