import * as React from "react";
import unified from "unified";
import parse from "rehype-parse";
import stringify from "rehype-stringify";
import wrapAll from "rehype-wrap-all";
import addClasses from "rehype-add-classes";
import JsxParser from "react-jsx-parser";

import {
  RichtextHeadingABorderLeft,
  RichtextHeadingABorderLeftNarrow,
  RichtextHeadingABorderRight,
  RichtextHeadingB,
  RichtextHeadingC,
  RichtextHeadingD,
  RichtextHeadingE,
  RichtextP,
  RichtextTextListBold,
  RichtextTextListCheck,
} from "@topm/shared";

export interface IWrapConfigItem {
  selector: string;
  wrapper: string;
}
interface IAddClassesConfig {
  [key: string]: string;
}
interface IRichtextFormatterProps {
  html: string;
  className?: string;
  wrapConfig?: IWrapConfigItem[];
  addClassesConfig?: IAddClassesConfig;
}

const RichtextFormatter: React.FC<IRichtextFormatterProps> = ({
  html,
  className,
  wrapConfig,
  addClassesConfig,
}) => {
  const processor = unified().use(parse, { fragment: true }).use(stringify);

  if (wrapConfig) {
    processor.use(wrapAll, wrapConfig);
  }
  if (addClassesConfig) {
    processor.use(addClasses, addClassesConfig);
  }

  const result = processor.processSync(html);

  result.contents = result.contents
    .toString()
    .replace(/<hr>/gi, "<hr />")
    .replace(/<br>/gi, "<br />")
    .replace(/<img([^>]+)>/gi, "<img$1 />");

  const components = {
    RichtextHeadingABorderLeft,
    RichtextHeadingABorderLeftNarrow,
    RichtextHeadingABorderRight,
    RichtextHeadingB,
    RichtextHeadingC,
    RichtextHeadingD,
    RichtextHeadingE,
    RichtextP,
    RichtextTextListBold,
    RichtextTextListCheck,
  };
  return (
    <div className={className}>
      <JsxParser components={components} jsx={result.contents} />
    </div>
  );
};

export { RichtextFormatter };
