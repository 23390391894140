import * as React from "react";
import ctl from "@netlify/classnames-template-literals";
import { ButtonRegistration } from "./button-registration";

interface ISubmitFlyoutProps {
  href: string;
}

const SubmitFlyout: React.FC<ISubmitFlyoutProps> = ({ href }) => {
  const rootClass = ctl(`
    fixed right-0 bottom-0 left-0 z-30
    bg-lp-gray2 bg-opacity-70 backdrop-filter backdrop-blur
    flex items-center justify-center
    py-vgap-md lg:py-vgap-md
    lp-shadow-d
  `);
  const innerClass = ctl(`
    flex flex-col lg:flex-row
    w-full lg:w-auto
    font-sans
  `);
  const textClass = ctl(`
    flex items-center justify-center
    text-sm lg:text-lg whitespace-nowrap
    px-15px lg:pr-8 lg:pl-0
    pb-vgap-sm lg:pb-0
    text-white
  `);
  const buttonWrapperClass = ctl(`
    px-15px lg:px-0
  `);
  return (
    <div className={rootClass}>
      <p className={innerClass}>
        <span className={textClass}>こちらからお申し込みください</span>
        <span className={buttonWrapperClass}>
          <ButtonRegistration
            size="md"
            className="w-full lg:w-auto"
            href={href}
          >
            セミナーに今すぐ申し込む
          </ButtonRegistration>
        </span>
      </p>
    </div>
  );
};

export { SubmitFlyout };
