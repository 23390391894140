import * as React from "react";
import { RichtextFormatter } from "./richtext-formatter";
import { FadeIn } from "./fade-in";
import { IWrapConfigItem } from "./richtext-formatter"

interface RichtextProps {
  html: string;
  className?: string;
  h2Style?: "leftBorder" | "rightBorder";
  ulStyle?: "bigText" | "check";
}

const Richtext: React.FC<RichtextProps> = ({
  html,
  className,
  h2Style = null,
  ulStyle = null,
}) => {
  const wrapConfig: IWrapConfigItem[] = [];

  // h2 style

  switch (h2Style) {
    case "leftBorder":
      wrapConfig.push({
        selector: "h2",
        wrapper: "RichtextHeadingABorderLeft",
      });
      break;
    case "rightBorder":
      wrapConfig.push({
        selector: "h2",
        wrapper: "RichtextHeadingABorderRight",
      });
      break;
    default:
      wrapConfig.push({
        selector: "h2",
        wrapper: "RichtextHeadingABorderLeftNarrow",
      });
      break;
  }

  wrapConfig.push({
    selector: "h3",
    wrapper: "RichtextHeadingD",
  });
  wrapConfig.push({
    selector: "h4",
    wrapper: "RichtextHeadingE",
  });
  wrapConfig.push({
    selector: "p",
    wrapper: "RichtextP",
  });

  // ul style

  switch (ulStyle) {
    case "bigText":
      wrapConfig.push({
        selector: "ul",
        wrapper: "RichtextTextListBold",
      });
      break;
    default:
      wrapConfig.push({
        selector: "ul",
        wrapper: "RichtextTextListCheck",
      });
      break;
  }

  return (
    <div className={className}>
      <FadeIn>
        <RichtextFormatter
          html={html}
          className="pb-vgap-md xl:text-lg"
          wrapConfig={wrapConfig}
        />
      </FadeIn>
    </div>
  );
};

export { Richtext };
