import * as React from 'react';

const HeadingB: React.FC = ({ children }) => (
  <div className="font-bold md:text-lg lg:text-base xl:text-xl">
    <span className="inline-block border-b border-lp-foreground">
      {children}
    </span>
  </div>
);

export { HeadingB };
