import * as React from "react";
import ctl from "@netlify/classnames-template-literals";

interface ILabelProps {
  className?: string;
}

const Label: React.FC<ILabelProps> = ({ children, className }) => {
  const cls = ctl(`
    bg-yellow-500
    ${className}
  `);
  return <div className={cls}>{children}</div>;
};

export { Label };
