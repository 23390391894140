import * as React from "react";
import { css } from "@emotion/css";
import tw, { theme } from "twin.macro";
import Sign from "../svgs/sign.inline.svg";

const s = {
  container: css`
    ${tw`mb-vgap-xl font-serif text-lp-black`}
    background: rgb(181, 181, 182);
    background: linear-gradient(
      128deg,
      rgba(181, 181, 182, 1) 0%,
      rgba(230, 230, 230, 1) 22%,
      rgba(230, 230, 230, 1) 80%,
      rgba(181, 181, 182, 1) 100%
    );
    padding: 20px 15px 15px;
    @media (min-width: ${theme`screens.md`}) {
      padding: 35px 35px 15px;
    }
    @media (min-width: ${theme`screens.lg`}) {
      padding: 50px 60px 30px;
    }
    box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px,
      rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px,
      rgba(0, 0, 0, 0.09) 0px 32px 16px;
  `,
  letter: css`
    background-image: repeating-linear-gradient(
      ${theme`colors.lp-transparent`} 0px,
      ${theme`colors.lp-transparent`} 39px,
      ${theme`colors.lp-black`} 40px
    );
    background-position: 0 -8px;
    margin-top: -10px;
    ${tw`bg-repeat`}
    @media (min-width: ${theme`screens.lg`}) {
      ${tw`tracking-wider`}
      margin-top: -20px;
      background-image: repeating-linear-gradient(
        ${theme`colors.lp-transparent`} 0px,
        ${theme`colors.lp-transparent`} 79px,
        ${theme`colors.lp-black`} 80px
      );
      background-position: 0 -15px;
      margin-top: -20px;
    }
    &::after {
      content: "";
      margin-top: -8px;
      ${tw`block lg:mt-[-15px]`}
    }
  `,
  letterInner: css`
    margin: 0 -5px 0 0;
    font-size: 0;
    line-height: 0;
    li {
      ${tw`inline whitespace-nowrap`}
      font-size: 17px;
      line-height: 40px;
      @media (min-width: ${theme`screens.lg`}) {
        font-size: 32px;
        line-height: 80px;
      }
    }
  `,
  sign: css`
    ${tw`flex justify-end mt-[15px] lg:mt-[25px]`}
  `,
  signSvg: css`
    width: 140px;
    height: 55px;
    @media (min-width: ${theme`screens.lg`}) {
      width: 205px;
      height: 81px;
    }
  `,
};

interface LetterBlockProps {
  html?: string;
  className?: string;
}

const fixHtml = (html: string): string => {
  // safari needs whitespace between li elements
  // to allow line break
  return html.replace(/<li>/gi, " <li>");
};

const LetterBlock: React.FC<LetterBlockProps> = ({ html, className }) => (
  <div className={className}>
    <div className={s.container}>
      <div className={s.letter}>
        <div
          className={s.letterInner}
          dangerouslySetInnerHTML={{ __html: fixHtml(html) }}
        ></div>
      </div>
      <div className={s.sign}>
        <Sign className={s.signSvg} role="img" aria-label="Peter F Drucker" />
      </div>
    </div>
  </div>
);

export { LetterBlock };
