import * as React from 'react';
import ctl from "@netlify/classnames-template-literals";
import tw, { theme } from "twin.macro";
import { css } from "@emotion/css";

const styles = {
  container: css`
    ${tw`relative pb-vgap-sm mb-vgap-lg`}
    ${tw`text-lp-accent tracking-wider`}
    ${tw`text-xl xl:text-2xl 2xl:text-3xl`}
    &::before {
      ${tw`text-lp-accent`}
      content: "";
      ${tw`block absolute bottom-0 w-full`};
      ${tw`border-b border-lp-accent`};
      @media (min-width: ${theme`screens.lg`}) {
        width: 160px;
      }
    }
    &::after {
      ${tw`text-lp-accent`} /* putting this avoids bug around Tailwind config override */
      content: "";
      ${tw`block h-px absolute bottom-0 right-0`}
    }
  `,
  borderLeft: css`
    &::before {
      ${tw`text-lp-accent`}
      ${tw`lg:right-full`}
    }
    &::after {
      ${tw`text-lp-accent`}
      ${tw`left-0 bg-lp-accent`}
      background: linear-gradient(
        90deg,
        ${theme`colors.lp-accent`} 39%,
        ${theme`colors.lp-transparent`} 100%
      );
    }
  `,
  borderRight: css`
    &::before {
      ${tw`text-lp-accent`}
      ${tw`lg:left-full`}
    }
    &::after {
      ${tw`text-lp-accent`}
      ${tw`lg:-left-28 bg-lp-accent`}
      background: linear-gradient(
        90deg,
        ${theme`colors.lp-transparent`} 0,
        ${theme`colors.lp-accent`} 40%
      );
    }
  `,
  narrow: css`
    &::after {
      ${tw`text-lp-accent`}
      ${tw`w-4/5`}
    }
  `,
};

interface HeadingAProps {
  borderLeft?: boolean;
  borderRight?: boolean;
  narrow?: boolean;
}

const HeadingA: React.FC<HeadingAProps> = ({
  children,
  borderLeft = false,
  borderRight = false,
  narrow = false,
}) => {
  const s = styles;
  const className = ctl(`
    ${s.container}
    ${borderLeft && s.borderLeft}
    ${borderRight && s.borderRight}
    ${narrow && s.narrow}
  `);
  return <div className={className}>{children}</div>;
};

const HeadingABorderLeft: React.FC<HeadingAProps> = (props) => {
  return <HeadingA borderLeft {...props} />;
};
const HeadingABorderLeftNarrow: React.FC<HeadingAProps> = (props) => {
  return <HeadingA borderLeft narrow {...props} />;
};
const HeadingABorderRight: React.FC<HeadingAProps> = (props) => {
  return <HeadingA borderRight {...props} />;
};

export {
  HeadingA,
  HeadingABorderLeft,
  HeadingABorderLeftNarrow,
  HeadingABorderRight,
};
