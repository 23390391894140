import * as React from "react";
import { ButtonRegistration } from "./button-registration";
import { FadeIn } from "./fade-in";

interface RegistrationNavProps {
  href: string;
}

const RegistrationNav: React.FC<RegistrationNavProps> = ({ href }) => {
  return (
    <div className="text-center md:pt-vgap-md pb-vgap-xl">
      <FadeIn>
        <ButtonRegistration size="lg" href={href}>
          セミナーに今すぐ申し込む
        </ButtonRegistration>
      </FadeIn>
    </div>
  );
};

export { RegistrationNav };
