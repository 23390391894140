import * as React from 'react';
import { css, keyframes } from "@emotion/css";
import ScrollAnimation from "react-animate-on-scroll";

const fadeInAnimation: string = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, 40px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

const animateInRule: string = css`
  animation-name: ${fadeInAnimation};
`;

const FadeIn: React.FC = ({ children }) => {
  return (
    <ScrollAnimation
      animateIn={animateInRule}
      animateOnce={true}
      duration={0.6}
    >
      {children}
    </ScrollAnimation>
  );
};

export { FadeIn };
