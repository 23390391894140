import * as React from 'react';
import tw from "twin.macro";
import { css } from "@emotion/css";

const styles = {
  container: css`
    ${tw`pb-vgap-md`}
    ${tw`font-bold tracking-wider`}
    ${tw`xl:text-lg`}
    ul {
      ${tw`xl:space-y-vgap-xs`}
    }
  `,
};

const TextListBold: React.FC = ({ children }) => {
  return <div className={styles.container}>{children}</div>;
};

export { TextListBold };
