import * as React from "react";
import Icon from "../svgs/back-to-top.inline.svg";

const BackToTop: React.FC = () => {
  return (
    <div className="hidden lg:block fixed right-6 bottom-28 z-50">
      <a href="#top">
        <Icon className="lp-shadow-a w-[70px] h-[70px] rounded-full" />
      </a>
    </div>
  );
};

export { BackToTop };
