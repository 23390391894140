import * as React from 'react';
import ctl from "@netlify/classnames-template-literals";

const HeadingD: React.FC = ({ children }) => {
  const rootClass: string = ctl(`
    mt-vgap-md first:mt-0
    relative text-lp-accent pb-vgap-md
    text-base md:text-lg xl:text-xl 2xl:text-2xl
    pl-[24px]
  `);
  const markerClass: string = ctl(`
    border-solid border-lp-accent bg-lp-accent
    border-t-[24px] border-r-[12px] border-b-0 border-l-0
    inline-block align-middle relative top-[-0.1em]
    mr-[10px] ml-[-24px]
  `);
  return (
    <div className={rootClass}>
      <span className={markerClass}></span>
      <div className="inline-block align-top">{children}</div>
    </div>
  );
};

export { HeadingD };
