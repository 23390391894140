import * as React from "react";
import { css } from "@emotion/css";
import tw from "twin.macro";
import { NthShape } from "./nth-shape";
import { FadeIn } from "./fade-in";
import { RichtextHeadingB } from "@topm/shared";

interface ItemProps {
  n: number | string;
  title: string;
}
interface ProgramDetailProps {
  className?: string;
}
interface IProgramDetail extends React.FC<ProgramDetailProps> {
  Item: React.FC<ItemProps>;
};

const s = {
  list: css`
    ${tw`pt-vgap-sm text-sm lg:text-base`}
    ol {
      ${tw`list-decimal ml-6`}
    }
    ol li br {
      ${tw`block md:hidden`}
    }
  `,
};

const Item: React.FC<ItemProps> = ({ n, title, children }) => {
  return (
    <FadeIn>
      <div className="table">
        <dl className="table-row">
          <dt className="table-cell whitespace-nowrap">
            <NthShape order={n} />
          </dt>
          <dd className="table-cell w-full pl-15px align-middle">
            <dl>
              <dt>
                <RichtextHeadingB>{title}</RichtextHeadingB>
              </dt>
              <dd className={s.list}>{children}</dd>
            </dl>
          </dd>
        </dl>
      </div>
    </FadeIn>
  );
};

const ProgramDetail: IProgramDetail = ({ children, className }) => {
  return <div className={`space-y-vgap-md ${className}`}> {children} </div>;
};

ProgramDetail.Item = Item;

export { ProgramDetail };
