import * as React from "react";

const Footer: React.FC = () => {
  return (
    <div className="bg-lp-gray3 pb-28">
      <footer
        className="
          px-15px py-vgap-md
          text-center font-sans
          text-xs md:text-sm
          space-y-vgap-sm
          mt-vgap-xl lg:mt-vgap-2xl
        "
      >
        <p>
          <span className="lg:whitespace-nowrap">
            本セミナーは、山下淳一郎の著書『ドラッカー5つの質問』に基づいた内容です。
          </span>
          <span className="lg:whitespace-nowrap">
            ダイヤモンド社及びドラッカー・リテラリー・トラストと一切関係ありません。
          </span>
        </p>
        <p>
          <small>Copyright © 2021 topmanagement All Rights Reserved.</small>
        </p>
      </footer>
    </div>
  );
};

export { Footer };
