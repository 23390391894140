import * as React from "react";
import Img, { FluidObject, GatsbyImageFluidProps } from "gatsby-image";
import { css } from "@emotion/css";
import tw from "twin.macro";
import * as cheerio from "cheerio";
import { RichtextFormatter } from "./richtext-formatter";
import { FadeIn } from "./fade-in";

import { RichtextHeadingABorderLeftNarrow } from "@topm/shared";
import { RichtextHeadingC } from "@topm/shared";

interface SectionProps {
  html: string;
}
interface Section1Props extends SectionProps {
  imgFluidProfile?: FluidObject;
}
interface Section2Props extends SectionProps {
  imgFluidBooks?: FluidObject;
}
interface ProfileBlockProps {
  className?: string;
  section1: string;
  section2: string;
  section3: string;
  section4?: string;
  imgFluidProfile?: FluidObject;
  imgFluidBooks?: FluidObject;
}

const s = {
  richtext: css`
    p {
      ${tw`mb-vgap-md`}
    }
    ul {
      ${tw`ml-20px space-y-vgap-xs lg:text-sm`}
      text-indent: -20px;
    }
    ul li::before {
      content: "・";
      ${tw`inline-block`}
      width: 20px;
      text-indent: 0;
    }
  `,
};

const Section1: React.FC<Section1Props> = ({ html, imgFluidProfile }) => {
  const $ = cheerio.load(html);
  const html_h2 = $("h2").html();
  const html_h3 = $("h3").html();
  const html_h4 = $("h4").html();
  const html_ul = $("ul").html();
  const html_p = $("p").html();

  return (
    <>
      <FadeIn>
        <RichtextHeadingABorderLeftNarrow>
          <h2>{html_h2}</h2>
        </RichtextHeadingABorderLeftNarrow>
        <RichtextHeadingC>
          <h3>{html_h3}</h3>
        </RichtextHeadingC>
      </FadeIn>
      <div className="flex mb-vgap-lg">
        <div className="hidden lg:block w-1/4 flex-none max-w-[390px]">
          {imgFluidProfile && (
            <Img fluid={imgFluidProfile} alt="" className="block w-full" />
          )}
        </div>
        <div className="lg:pl-30px">
          <FadeIn>
            <dl className="lg:flex lg:flex-row pb-vgap-md lg:items-center -ml-6 lg:ml-0">
              <dt
                className="inline-block align-middle pl-6 pb-2 lg:pb-0 lg:pl-0 lg:block lg:order-2 text-3xl whitespace-nowrap"
                dangerouslySetInnerHTML={{ __html: html_h4 }}
              />
              <dd className="inline-block align-middle pl-6 lg:pl-0 lg:block lg:flex-none lg:order-1 pb-vgap-sm lg:pb-0">
                <div className="text-xs lg:text-sm lg:border-r lg:border-white lg:pr-4 lg:mr-4">
                  <ul dangerouslySetInnerHTML={{ __html: html_ul }} />
                </div>
              </dd>
            </dl>
          </FadeIn>
          <div className="clearfix">
            {imgFluidProfile && (
              <Img
                fluid={imgFluidProfile}
                alt=""
                className="block float-right lg:hidden w-5/12 ml-4 mb-3"
              />
            )}
            <FadeIn>
              <p dangerouslySetInnerHTML={{ __html: html_p }} />
            </FadeIn>
          </div>
        </div>
      </div>
    </>
  );
};

const Section2: React.FC<Section2Props> = ({ html, imgFluidBooks }) => {
  const $ = cheerio.load(html);
  const html_h3 = $("h3").html();
  $("h3").remove();

  return (
    <>
      <FadeIn>
        <RichtextHeadingC>
          <h3>{html_h3}</h3>
        </RichtextHeadingC>
      </FadeIn>
      <div className="flex flex-col lg:flex-row pb-vgap-lg">
        <div className="w-full lg:flex-none lg:w-1/2 2xl:w-4/5 max-w-[700px]">
          <div className="mb-vgap-lg lg:mb-0 pt-[26.9%] relative">
            <div className="absolute left-0 top-0 right-0 w-full">
              {imgFluidBooks && <Img fluid={imgFluidBooks} alt="" />}
            </div>
          </div>
        </div>
        <div className="lg:pl-30px">
          <FadeIn>
            <div
              className={s.richtext}
              dangerouslySetInnerHTML={{ __html: $.root().html() }}
            />
          </FadeIn>
        </div>
      </div>
    </>
  );
};

const Section3: React.FC<SectionProps> = ({ html }) => {
  return (
    <div className="relative z-10 pb-vgap-sm">
      <FadeIn>
        <RichtextFormatter
          html={html}
          className={s.richtext}
          wrapConfig={[
            {
              selector: "h3",
              wrapper: "RichtextHeadingC",
            },
          ]}
        />
      </FadeIn>
    </div>
  );
};

const ProfileBlock: React.FC<ProfileBlockProps> = ({
  className,
  section1,
  section2,
  section3,
  section4,
  imgFluidProfile,
  imgFluidBooks,
}) => {
  return (
    <div className={className}>
      <div
        className="
          text-white bg-lp-gray shadow-xl
          py-vgap-xl
          relative z-10
          lp-fill-horizontally
          lp-shadow-c
        "
      >
        <Section1 html={section1} imgFluidProfile={imgFluidProfile} />
        { section4 && <Section3 html={section4} /> }
        <Section2 html={section2} imgFluidBooks={imgFluidBooks} />
        <Section3 html={section3} />
      </div>
    </div>
  );
};

export { ProfileBlock };
