import * as React from 'react';
import ctl from "@netlify/classnames-template-literals";

interface IContentWrapperProps {
  className?: string;
  noSidePad?: boolean;
  noTrans?: boolean;
  noShadow?: boolean;
}

const rootClass = ({
  className,
  noSidePad,
  noTrans,
  noShadow,
}: IContentWrapperProps): string => {
  return ctl(`
    ${className}
    max-w-[1200px] mx-auto
    ${noSidePad ? "px-0" : "px-20px lg:px-30px xl:px-60px"}
    ${noTrans && "bg-opacity-100"}
    ${!noShadow && "shadow-2xl"}
  `);
};

const ContentWrapper: React.FC<IContentWrapperProps> = ({
  children,
  className,
  noSidePad = false,
  noTrans = false,
  noShadow = false,
}) => {
  return (
    <div className={rootClass({ className, noSidePad, noTrans, noShadow })}>
      {children}
    </div>
  );
};

export { ContentWrapper };
