import * as React from "react";
import ctl from "@netlify/classnames-template-literals";
import Icon from "../svgs/arrow-double-right.inline.svg";

type Size = "md" | "lg";

interface ButtonRegistrationProps {
  className?: string;
  size: Size;
  href: string;
}

const ButtonRegistration: React.FC<ButtonRegistrationProps> = ({
  className,
  children,
  size,
  href,
}) => {
  let rootClass = ctl(`
    inline-flex rounded-md
    items-center justify-center
    text-white font-sans
    no-underline hover:underline
    tracking-widest whitespace-nowrap
    lp-gradient-a
    ${className}
  `);
  if (size === "md") {
    rootClass = ctl(`
      ${rootClass}
      text-sm md:text-base lg:text-lg
      py-3 pr-12 pl-10
    `);
  }
  if (size === "lg") {
    rootClass = ctl(`
      ${rootClass}
      text-base md:text-lg lg:text-xl
      py-4 pr-10 pl-8 lg:pr-14 lg:pl-12
    `);
  }

  const iconClass = ctl(`
    ${size === "md" && "w-[16px] h-16px lg:w-[30px] lg:h-30px"}
    ${size === "lg" && "w-[30px] h-30px"}
  `);

  return (
    <a href={href} className={rootClass} target="_blank" rel="noreferrer">
      <Icon className={iconClass} />
      <span className="pl-1 tracking-wider">{children}</span>
    </a>
  );
};

export { ButtonRegistration };
