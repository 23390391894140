import * as React from "react";
import * as cheerio from "cheerio";
import ctl from "@netlify/classnames-template-literals";
import { nanoid } from "nanoid";
import reactElementToJSXString from "react-element-to-jsx-string";
import Check from "../../svgs/check.inline.svg";
import Check2 from "../../svgs/check2.inline.svg";

/*
  should receive react component like below
  <ul>
    <li>The quick brown fox jumps over the lazy dog. </li>
    <li>The quick brown fox jumps over the lazy dog. </li>
    <li>The quick brown fox jumps over the lazy dog. </li>
  </ul>
*/

const Icon: React.FC = () => {
  const className = ctl(`
    block absolute top-0
    w-[20px] h-[20px] left-[-26px]
    md:w-[28px] md:h-[28px] md:left-[-36px]
  `);
  const isLp4 =
    typeof window !== "undefined" &&
    window.document.body.dataset.packageId === "lp4";
  return (
    <>
      {!isLp4 && <Check className={className} />}
      {isLp4 && <Check2 className={className} />}
    </>
  );
};

interface TextWrapperProps {
  html: string;
}

const TextWrapper: React.FC<TextWrapperProps> = ({ html }) => (
  <span
    dangerouslySetInnerHTML={{
      __html: html,
    }}
  ></span>
);

const insertSvgs = (ul: React.ReactElement): React.ReactElement => {
  // 1つの時はmapが使えないらしいのでその場合は強制的に[]に入れる
  const children = ul.props.children.map
    ? ul.props.children
    : [ul.props.children];
  return (
    <ul className="space-y-vgap-sm">
      {children.map((El) => {
        // strip white spaces
        if (El.type !== "li") {
          return null;
        }
        var $ = cheerio.load(reactElementToJSXString(El));
        return (
          <li key={nanoid()} className="relative ml-[23px] md:ml-[32px]">
            <Icon />
            <TextWrapper html={$("li").html()} />
          </li>
        );
      })}
    </ul>
  );
};

interface TextListCheckProps {
  children: React.ReactElement;
}

const TextListCheck: React.FC<TextListCheckProps> = ({ children }) => {
  return <div className="pb-vgap-md xl:text-lg">{insertSvgs(children)}</div>;
};

export { TextListCheck };
