import * as React from "react";
import ctl from "@netlify/classnames-template-literals";

interface IProps {
  className?: string;
}
interface ISplitVertically extends React.FC<IProps> {
  Area: React.FC<IProps>;
}

const Area: React.FC<IProps> = ({ children, className }) => {
  return <div className={`pl-30px lg:w-1/2 ${className}`}>{children}</div>;
};

const SplitVertically: ISplitVertically = ({ children, className }) => {
  return (
    <div className={ctl(`-ml-30px ${className}`)}>
      <div className="lg:flex lg:w-full">{children}</div>
    </div>
  );
};

SplitVertically.Area = Area;

export { SplitVertically };
